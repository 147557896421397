import { cloneDeep } from 'lodash-es';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    organization: null,
    configSet: {},
    allegroAuthorization: {},
  },
  getters: {
    getOrganization: (state) => () => state.organization,
    getConfigurationSet: (state) => () => state.configSet,
    getAllegroAuthorization: (state) => () => state.allegroAuthorization,
  },
  actions: {
    setAllegroAuthorization: (context, { authorizationData }) => {
      context.commit('setAllegroAuthorization', authorizationData);
    },
    setAdditionalServices: (context, { additionalServices }) => {
      context.commit('setAdditionalServices', additionalServices);
    },
    setFieldsMappings: (context, { fieldsMappings }) => {
      context.commit('setFieldsMappings', fieldsMappings);
    },
    setOrganization: (context, { organization }) => {
      context.commit('setOrganization', organization);
    },
    setConfigSet: (context, { response }) => {
      context.commit('createConfigSet', response);
    },
    clearValues: (context) => {
      context.commit('clearValues');
    },
  },
  mutations: {
    setAllegroAuthorization(state, authorizationData) {
      Vue.set(state, 'allegroAuthorization', cloneDeep(authorizationData));
    },
    setAdditionalServices(state, additionalServices) {
      Vue.set(state.configSet, 'additionalServices', additionalServices);
    },
    setFieldsMappings(state, fieldsMappings) {
      Vue.set(state.configSet, 'fieldsMappings', fieldsMappings);
    },
    setOrganization(state, organization) {
      Vue.set(state, 'organization', cloneDeep(organization));
    },
    createConfigSet(state, data) {
      Vue.set(state, 'configSet', cloneDeep(data));
    },
    clearValues(state) {
      Vue.set(state, 'configSet', {});
      Vue.set(state, 'allegroAuthorization', {});
    },
  },
};
