import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import './plugins/vee-validate';
import './plugins/snotify';
import './plugins/loading';
import './assets/styles/global.scss';
import { initKeycloak } from './services/keycloak.ts';
import FormSection from './components/shared/FormSection.vue';
import SelectField from './components/inputs/SelectField.vue';
import TextField from './components/inputs/TextField.vue';
import TextAreaField from './components/inputs/TextAreaField.vue';
import ServiceField from './components/inputs/ServiceField.vue';
import CheckField from './components/inputs/CheckField.vue';

Vue.config.productionTip = false;

Vue.component('FormSection', FormSection);
Vue.component('SelectField', SelectField);
Vue.component('TextField', TextField);
Vue.component('TextAreaField', TextAreaField);
Vue.component('ServiceField', ServiceField);
Vue.component('CheckField', CheckField);

initKeycloak().finally(() => {
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
});
